import VideoPlayer from '../VideoPlayer';

const VideoModule = (props) => {

  const { module } = props;

  if (module.value && module.value !== '') {
    return (
      <div className="module module--video">
        <VideoPlayer {...props} url={module.value} />
      </div>
    );
  } else {
    return null;
  }
}

export default VideoModule;