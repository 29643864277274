const Circle = ({ shapeColor, handleClick }) => {
  return (
    <svg
      onClick={ handleClick }
      style={ { fill: shapeColor ? shapeColor : null } }
      className="shape shape--Circle"
      xmlns="http://www.w3.org/2000/svg"
      width="325"
      height="325"
      viewBox="0 0 325 325"
    >
      <circle cx="162.5" cy="162.5" r="162.5" />
    </svg>
  )
};

export default Circle;