import { motion } from 'framer-motion';
import { useState } from 'react';
import { useEffect } from 'react';
import Marquee from 'react-fast-marquee';
import Module from '../components/Page/Module';

const Page = (props) => {

  const { page, windowWidth } = props;
  const { title, content } = page;
  const [ marqueeCount, setMarqueeCount ] = useState(10);

  useEffect(() => {
    const titleEl = document.createElement('span');
    titleEl.className = 'page__heading__marquee__span font--huge';
    titleEl.innerHTML = title;
    titleEl.style.opacity = '0';
    titleEl.style.position = 'absolute';
    titleEl.style.pointerEvents = 'none';
    document.body.appendChild(titleEl);
    const titleElWidth = titleEl.offsetWidth;
    document.body.removeChild(titleEl);
    const marqueeCountNumber = Math.round(windowWidth / titleElWidth) * 2;
    setMarqueeCount(marqueeCountNumber);
  }, [windowWidth, title]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="page"
    >
      <h2 className="page__heading">
        <Marquee
          duration={60000}
          height="160px"
          reverse={true}
          gradient={false}
        >
          {
            Array(marqueeCount).fill('').map(
              (item, index) => (
                <span key={index} className="page__heading__marquee__span font--huge">{title}</span>
              )
            )
          }
        </Marquee>
      </h2>
      <div className="page__content container-fluid">
        {
          content &&
          content.map(
            (module, index) => (
              <Module key={index} {...props} module={module} marqueeCount={marqueeCount} />
            )
          )
        }
      </div>
    </motion.div>
  )
}

export default Page;