const OvalPortrait = ({ shapeColor, handleClick }) => {
  return (
    <svg
      onClick={handleClick}
      style={{ fill: shapeColor ? shapeColor : null }}
      className="shape shape--ovalPortrait"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 142.64 242.36"
      width="142.64" height="242.36"
    >
      <ellipse cx="71.32" cy="121.18" rx="71.32" ry="121.18" />
    </svg>
  )
};

export default OvalPortrait;