import { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import Heart from './shapes/Heart';
import ContactEnvelope from './shapes/ContactEnvelope';
import FlowerLandscape from './shapes/FlowerLandscape';
import FlowerPortrait from './shapes/FlowerPortrait';
import Splat from './shapes/Splat';
import Spiky from './shapes/Spiky';
import Circle from './shapes/Circle';
import Triangle from './shapes/Triangle';
import OvalLandscape from './shapes/OvalLandscape';
import OvalPortrait from './shapes/OvalPortrait';
import Square from './shapes/Square';
import Oblong from './shapes/Oblong';
import Pill from './shapes/Pill';
import Diamond from './shapes/Diamond';
import FourLeafClover from './shapes/FourLeafClover';

const HomepagePageLinkShape = (props) => {

  const { page, handleClick } = props;
  const { shape, shapeColor } = page;

  switch(shape) {
    case 'heart':
      return <Heart shapeColor={shapeColor} handleClick={handleClick} />;
    case 'contactEnvelope':
      return <ContactEnvelope shapeColor={shapeColor} handleClick={handleClick} />;
    case 'flower landscape':
      return <FlowerLandscape shapeColor={shapeColor} handleClick={handleClick} />;
    case 'flower portrait':
      return <FlowerPortrait shapeColor={shapeColor} handleClick={handleClick} />;
    case 'oval landscape':
      return <OvalLandscape shapeColor={shapeColor} handleClick={handleClick} />;
    case 'oval portrait':
      return <OvalPortrait shapeColor={shapeColor} handleClick={handleClick} />;
    case 'splat':
      return <Splat shapeColor={shapeColor} handleClick={handleClick} />;
    case 'spiky':
      return <Spiky shapeColor={shapeColor} handleClick={handleClick} />;
    case 'circle':
      return <Circle shapeColor={shapeColor} handleClick={handleClick} />;
    case 'square':
      return <Square shapeColor={shapeColor} handleClick={handleClick} />;
    case 'triangle':
      return <Triangle shapeColor={shapeColor} handleClick={handleClick} />;
    case 'oblong':
      return <Oblong shapeColor={shapeColor} handleClick={handleClick} />;
    case 'pill':
      return <Pill shapeColor={shapeColor} handleClick={handleClick} />;
    case 'diamond':
      return <Diamond shapeColor={shapeColor} handleClick={handleClick} />;
    case 'four leaf clover':
      return <FourLeafClover shapeColor={shapeColor} handleClick={handleClick} />;
    default: return null;
  }
}

const HomepagePageLink = (props) => {

  const { pages, setPages, page, windowWidth, windowHeight, container, options } = props;
  const { title, title_slug } = page;
  const inner = useRef();
  const mouseDownTimeout = useRef();
  const mouseIsDown = useRef(false);
  const isDragging = useRef(false);
  const [position] = useState({
    x: Math.random() * 100,
    y: Math.random() * 100
  });

  const handleMouseUp = (e) => {
    clearTimeout(mouseDownTimeout.current);
    mouseDownTimeout.current = setTimeout(() => {
      mouseIsDown.current = false;
      isDragging.current = false;
    }, 400);
  };

  const handleClick = () => {
    if (isDragging.current === false) {
      if (page._id !== 'email') {
        props.navigate(`/page/${title_slug}`);
      } else {
        window.open(`mailto:${options.email}`);
      }
    }
  };

  const handleMouseDown = () => {
    const pagesArray = [...pages];

    let highestIndex = 0;
    for (let pageItem of pagesArray) {
      if (pageItem.index > highestIndex) {
        highestIndex = pageItem.index;
      }
    }
    for (let pageItem of pagesArray) {
      if (pageItem._id !== page._id) {
        if (pageItem.index > page.index - 1) {
          pageItem.index--;
        }
      } else {
        pageItem.index = pages.length + 1;
      }
    }
    setPages(pagesArray);
  };

  return (
    <motion.div
      drag
      dragConstraints={container}
      style={{
        zIndex: page.index + 1
      }}
      initial={{
        x: Math.min(windowWidth - 240, position.x / 100 * windowWidth),
        y: Math.min(windowHeight - 240, position.y / 100 * windowHeight)
      }}
      transition={{
        duration: 0.4,
        delay: 0.4
      }}
      className={`homepage__page-link__wrapper${page.shape ? ` homepage__page-link__wrapper--${page.shape}` : ''}`}
      onMouseDown={() => {
        handleMouseDown();
        mouseIsDown.current = true;
        mouseDownTimeout.current = setTimeout(() => {
          isDragging.current = true;
        }, 200);
      }}
      onMouseMove={() => {
        if (mouseIsDown.current === true) {
          isDragging.current = true;
        }
      }}
      onTouchStart={() => {
        handleMouseDown();
      }}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onBlur={handleMouseUp}
    >
      <div
        ref={inner}
        className="homepage__page-link__inner"
      >
        <HomepagePageLinkShape {...props} handleClick={handleClick} />
        <h3 className={`homepage__page-link__title${page._id === 'email' ? ' contact' : ''}`}>{title}</h3>
      </div>
    </motion.div>
  )
};

export default HomepagePageLink;