const Pill = ({ shapeColor, handleClick }) => {
  return (
    <svg
      onClick={handleClick}
      style={{ fill: shapeColor ? shapeColor : null }}
      className="shape shape--pill"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500.22 174.95"
      width="500.22" height="174.95"
    >
      <path d="M412.75,.5H87.47C39.44,.5,.5,39.44,.5,87.47s38.94,86.97,86.97,86.97H412.75c48.03,0,86.97-38.94,86.97-86.97S460.78,.5,412.75,.5Z" />
    </svg>
  )
};

export default Pill;