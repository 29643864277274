import { Route, Routes } from 'react-router-dom';
import Header from './Header';
import Homepage from '../views/Homepage';
import About from '../views/About';
import Page from '../views/Page';
import { AnimatePresence } from 'framer-motion';
import Preview from '../utils/Preview';
import Cursor from './Cursor';
import Head from './Head';

const Content = (props) => {

  const { previewItem, windowHeight, pages, options } = props;

  return (
    <div
      className="app"
      style={{
        height: windowHeight + 'px'
      }}
    >
      <Preview {...props} />
      <Header {...props} />
      <Homepage {...props} />
      <AnimatePresence>
        {
          !previewItem._id &&
          <Routes key={props.location.pathname}>
            <Route
              path='/'
              element={<div />}
            />
            <Route
              path='about'
              element={<About {...props} />}
            />
            {
              pages &&
              pages.map((page, index) => (
                <Route
                  key={index}
                  path={`page/${page.title_slug}`}
                  element={<Page page={page} {...props} />}
                />
              ))
            }
            <Route
              path='*'
              element={<div />}
            />
          </Routes>
        }
        {
          previewItem._id &&
          pages.find((page) => page._id === previewItem._id) &&
          <Page page={previewItem} {...props} />
        }
        {
          props.userIsTouching === false &&
          <Cursor />
        }
      </AnimatePresence>
      {
        options.memojis &&
        options.memojis[0] &&
        <Head {...props} />
      }
    </div>
  );
};

export default Content;