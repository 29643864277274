import { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import LazyloadImage from './LazyloadImage';
import { Link } from 'react-router-dom';

const Head = (props) => {

  const { options, pathname } = props;
  const { memojis } = options;
  const [currentHead, setCurrentHead] = useState(memojis[Math.floor(Math.random() * memojis.length)]);

  useEffect(() => {
    setCurrentHead(memojis[Math.floor(Math.random() * memojis.length)]);
  }, [pathname, memojis]);

  return (
    <AnimatePresence mode="wait">
      <motion.div
        initial={ { y: '120%' } }
        animate={ { y: 0 } }
        exit={ { y: '120%' } }
        transition={ { duration: 0.4, style: 'ease' } }
        className="head"
        onClick={ () => setCurrentHead(memojis[ Math.floor(Math.random() * memojis.length) ]) }
        key={ currentHead.path }
      >
        <Link to="/">
          <LazyloadImage image={currentHead} />
        </Link>
      </motion.div>
    </AnimatePresence>
  );
};

export default Head;