import { useRef, useEffect } from 'react';
import * as unicodeEmoji from 'unicode-emoji';

const Cursor = () => {

  const trail = useRef({});

  useEffect(() => {
    let raf;
    // dots is an array of Dot objects,
    // mouse is an object used to track the X and Y position
      // of the mouse, set with a mousemove event listener below
    const dots = [];
    const mouse = {
      x: 0,
      y: 0
    };

    // const emojis = EmojiSet.get({ only_emoji: true });
    const omitWhere = { versionAbove: '12.0', category: ['flags'], version: ['0.6', '0.7'] };

    const emojis = unicodeEmoji.getEmojis(omitWhere);

    // The Dot object used to scaffold the dots
    const Dot = function(index) {
      this.x = 0;
      this.y = 0;
      this.node = (function(){
        var n = document.createElement("div");
        n.className = "dot";
        n.textContent = emojis[Math.floor(Math.random() * emojis.length)].emoji;
        trail.current.appendChild(n);
        return n;
      }());
    };
    // The Dot.prototype.draw() method sets the position of 
      // the object's <div> node
    
    Dot.prototype.draw = function() {
      this.node.style.transform = `translate(${this.x}px, ${this.y}px)`;

      if (Math.abs(this.x - mouse.x) < 5 && Math.abs(this.y - mouse.y) < 5) {
        this.node.style.opacity = 0;
      } else {
        this.node.style.opacity = 1;
      }

    };
    
    // Creates the Dot objects, populates the dots array
    for (var i = 0; i < 12; i++) {
      const d = new Dot(i);
      dots.push(d);
    }

    // This is the screen redraw function
    const draw = () => {
      // Make sure the mouse position is set everytime
      // draw() is called.
      let x = mouse.x,
          y = mouse.y;
      
      // This loop is where all the 90s magic happens
      dots.forEach(function (dot, index, dots) {
        const nextDot = dots[index + 1] || dots[0];
        
        dot.x = x;
        dot.y = y;
        dot.draw();
        x += (nextDot.x - dot.x) * 0.9;
        y += (nextDot.y - dot.y) * 0.9;

      });
    }

    const handleMouseMove = (event) => {
      mouse.x = event.pageX;
      mouse.y = event.pageY;
      trail.current.classList.remove('inactive');
    }

    window.addEventListener('mousemove', handleMouseMove);

    // animate() calls draw() then recursively calls itself
      // everytime the screen repaints via requestAnimationFrame().
    function animate() {
      draw();
      raf = requestAnimationFrame(animate);
    }

    // And get it started by calling animate().
    animate();

    return () => {
      cancelAnimationFrame(raf);
      window.removeEventListener('mousemove', handleMouseMove);
      if (document.querySelector('.trail')) {
        document.querySelector('.trail').innerHTML = '';

      }
    }
  }, []);

  return (
    <div className="cursor">
      <div
        className="trail inactive"
        ref={trail}
      />
    </div>
  )
};

export default Cursor;