const Oblong = ({ shapeColor, handleClick }) => {
  return (
    <svg
      onClick={handleClick}
      style={{ fill: shapeColor ? shapeColor : null }}
      className="shape shape--oblong"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 325.28 173.95"
      width="325.28" height="173.95"
    >
      <rect width="325.28" height="173.95" />
    </svg>
  )
};

export default Oblong;