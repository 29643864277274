import { useState } from 'react';
import { YouTubeEmbed } from 'react-social-media-embed';

const YouTubeModule = (props) => {

  const { module, windowWidth } = props;

  const YOUTUBE_DEFAULT_HEIGHT = 390;
  const [ embedHeight, setEmbedHeight ] = useState(YOUTUBE_DEFAULT_HEIGHT);

  if (module.value && module.value !== '') {
    return (
      <div className='module module--video' style={ { display: 'flex', justifyContent: 'center', width: '100%' } }>
        <YouTubeEmbed
          url={ module.value }
          width={ Math.min(windowWidth - 80, 640) }
          height={embedHeight}
          youTubeProps={{
            onReady: async (r) =>
              (await r.target.getIframe()).addEventListener('load', () => setEmbedHeight((height) => height + 1)),
          } }
        />
      </div>
    );
  } else {
    return null;
  }
}

export default YouTubeModule;