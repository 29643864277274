import { InstagramEmbed } from 'react-social-media-embed';

const InstagramModule = (props) => {

  const { module, windowWidth } = props;

  if (module.value && module.value !== '') {
    return (
      <div className='module module--video' style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <InstagramEmbed url={ module.value } width={Math.min(windowWidth - 80, 640)} />
      </div>
    );
  } else {
    return null;
  }
}

export default InstagramModule;