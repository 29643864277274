const Diamond = ({ shapeColor, handleClick }) => {
  return (
    <svg
      onClick={handleClick}
      style={{ fill: shapeColor ? shapeColor : null }}
      className="shape shape--diamond"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 318.74 138.86"
    >
      <polygon points="317.49 69.43 159.37 138.32 1.25 69.43 159.37 .55 317.49 69.43" />
    </svg>
  )
};

export default Diamond;