const FourLeafClover = ({ shapeColor, handleClick }) => {
  return (
    <svg
      onClick={handleClick}
      style={{ fill: shapeColor ? shapeColor : null }}
      className="shape shape--fourLeafClover"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 232.24 232.24"
      width="232.24" height="232.24"
    >
      <path d="M211.9,116.12s0,0-.01-.01c25.91-26.49,25.75-68.96-.52-95.23-26.27-26.27-68.74-26.44-95.23-.52,0,0,0,0-.01-.01C89.67-6.11,46.79-6.11,20.34,20.34s-26.45,69.33,0,95.78c.18,.18,.37,.35,.55,.52,0,0,0,0-.01,.01-26.15,26.15-26.15,68.56,0,94.71,26.15,26.15,68.56,26.15,94.71,0,0,0,0,0,.01-.01,.18,.18,.34,.37,.52,.55,26.45,26.45,69.33,26.45,95.78,0,26.45-26.45,26.45-69.33,0-95.78Zm-96.33,.52s0,0,0,0c0,0,0,0,.01,.01s0,0,.01,.01c0,0,0,0,0,0,0,0,0,0-.01-.01s0,0-.01-.01Z" />
    </svg>
  )
};

export default FourLeafClover;