const Triangle = ({ shapeColor, handleClick }) => {
  return (
    <svg
      onClick={handleClick}
      style={{ fill: shapeColor ? shapeColor : null }}
      className="shape shape--triangle"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 430.61 196.27"
      width="430.61" height="196.27"
    >
      <path d="M207.55,2.85c4.48-3.79,11.04-3.79,15.52,0l203.28,172.27c5.06,4.28,5.68,11.86,1.4,16.91-2.28,2.69-5.63,4.24-9.16,4.24H12.02c-6.63,0-12-5.37-12-12,0-3.53,1.55-6.87,4.24-9.16L207.55,2.85Z" />
    </svg>
  )
};

export default Triangle;