import Marquee from 'react-fast-marquee';

const TickerModule = (props) => {

  const { module, marqueeCount } = props;

  if (module.value && module.value !== '') {
    return (
      <div className="module module--ticker row">
        
        <Marquee
          duration={60000}
          height="160px"
          reverse={true}
          gradient={false}
        >
          {
            Array(marqueeCount).fill('').map(
              (item, index) => (
                <span key={index} className="page__heading__marquee__span font--huge">{module.value}</span>
              )
            )
          }
        </Marquee>
      </div>
    );
  } else {
    return null;
  }
}

export default TickerModule;