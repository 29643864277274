import { useRef } from 'react';
import { Link } from 'react-router-dom';
import HomepagePageLink from '../components/HomepagePageLink';

const Homepage = (props) => {

  const { pages, setPages, windowWidth, windowHeight, navigate, options } = props;
  const container = useRef();

  return (
    <div
      className="homepage"
      style={{
        width: windowWidth + 'px',
        height: windowHeight + 'px'
      }}
    >
      {
        props.location.pathname !== '/' &&
        <Link to="/" className="homepage-link" />
      }
      <div className="items__container" ref={container}>
        {
          pages &&
          pages.map((page, index) => (
            (page._id !== 'email' || (options?.email && options?.email !== '')) &&
            <HomepagePageLink
              key={index}
              index={index}
              pages={pages}
              setPages={setPages}
              page={page}
              windowWidth={windowWidth}
              windowHeight={windowHeight}
              navigate={navigate}
              container={container}
              options={options}
            />
          ))
        }
      </div>
    </div>
  )
}

export default Homepage;