const Square = ({ shapeColor, handleClick }) => {
  return (
    <svg
      onClick={handleClick}
      style={{ fill: shapeColor ? shapeColor : null }}
      className="shape shape--square"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 242.94 242.94"
      width="242.94" height="242.94"
    >
      <rect width="242.94" height="242.94" />
    </svg>
  )
};

export default Square;