import { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchCockpitData } from './utils/cockpit';
import Content from './components/Content';

const App = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth
  });
  const { windowWidth, windowHeight } = windowDimensions;
  const [about, setAbout] = useState({});
  const [options, setOptions] = useState({});
  const [previewItem, setPreviewItem] = useState({});
  const [pages, setPages] = useState([]);
  const [userIsTouching, setUserIsTouching] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const wrapper = useRef();
  const main = useRef();

  useEffect(() => {
    const handleFetchPages = (data) => {
      const pagesArray = [];
      for (let i = 0; i < data.length; i++) {
        pagesArray.push({
          ...data[i],
          index: i + 2
        });
      }
      pagesArray.push({
        _id: 'email',
        index: data.length + 1,
        title: 'Contact',
        shape: 'contactEnvelope',
      });
      setPages(pagesArray);
    }
    fetchCockpitData('about', setAbout, false);
    fetchCockpitData('options', setOptions, false);
    fetchCockpitData('pages', handleFetchPages, true);
  }, []);

  useEffect(() => {
    let resizingTimeout;

    const handleWindowResize = () => {
      clearTimeout(resizingTimeout);
      setWindowDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth
      });
    }

    const handleTouchstart = () => {
      setUserIsTouching(true);
    }
    
    window.addEventListener('touchstart', handleTouchstart);

    const handleWheel = () => {
      setUserIsTouching(false);
    }
    
    window.addEventListener('wheel', handleWheel);

    // get the dimensions of the window on initial load
    handleWindowResize();

    //add an event listener to update the window dimensions on every window resize
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('touchstart', handleTouchstart);
    }
  }, []);

  return (
    <Content
      userIsTouching={userIsTouching}
      setUserIsTouching={setUserIsTouching}
      main={main}
      wrapper={wrapper}
      windowWidth={windowWidth}
      windowHeight={windowHeight}
      windowDimensions={windowDimensions}
      pathname={pathname}
      location={location}
      navigate={navigate}
      about={about}
      options={options}
      pages={pages}
      setPages={setPages}
      previewItem={previewItem}
      setPreviewItem={setPreviewItem}
    />
  );
}

export default App;