import TextModule from './TextModule';
import VideoModule from './VideoModule';
import ImageModule from './ImageModule';
import TickerModule from './TickerModule';
import CustomEmbedModule from './CustomEmbedModule';
import TikTokModule from './TikTokModule';
import InstagramModule from './InstagramModule';
import YouTubeModule from './YouTubeModule';

const Module = (props) => {

  const { module } = props;

  switch (module.field?.name) {
    case 'text':
      return <TextModule {...props} />
    case 'videoUrl':
      return <VideoModule {...props} />
    case 'image':
      return <ImageModule {...props} />
    case 'ticker':
      return <TickerModule {...props} />
    case 'customEmbed':
      return <CustomEmbedModule {...props} />
    case 'tiktok':
      return <TikTokModule {...props} />
    case 'youtube':
      return <YouTubeModule {...props} />
    case 'instagram':
      return <InstagramModule {...props} />
    default:
      return null;
  }
};

export default Module;