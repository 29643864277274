import { Link, useLocation } from 'react-router-dom';

const Header = (props) => {

  const location = useLocation();

  return (
    <header className="header">
      <Link to="/" className="header__home-link">
        <h1 className="title">Char Morrin</h1>
      </Link>
      <Link className="header__about-link" to="/about">?!</Link>
      {
        location.pathname !== '/' &&
        <Link className="header__back-link" to="/">back</Link>
      }
    </header>
  )
}

export default Header;