import parse, { attributesToProps, domToReact } from 'html-react-parser';
import { useMemo } from 'react';

const TextModule = (props) => {

  const { module } = props;
  const text = useMemo(() => {
    if (!module?.value) return '';
    let newText = module.value;
    newText = newText.replaceAll('<br/>', '<p style="margin: 0;">&nbsp;</p>');
    newText = newText.replaceAll('<br />', '<p style="margin: 0;">&nbsp;</p>');
    newText = newText.replaceAll('<br>', '<p style="margin: 0;">&nbsp;</p>');
    return newText;
  }, [ module.value ]);

  const options = {
    replace: (domNode) => {
      if (domNode.attribs) {
        const newDomNodeAttributes = {
          ...domNode.attribs,
          class: undefined,
          style: undefined,
        };
        const props = attributesToProps(newDomNodeAttributes);
        return (
          <domNode.name { ...props } children={ domNode.children } >
            {domToReact(domNode.children, options)}
          </domNode.name>
        );
      }
    }
  };

  if (text && text !== '') {
    return (
      <div className="module module--text">
        {/* {parse(text)} */ }
        {
          parse(text, options)
        }

      </div>
    );
  } else {
    return null;
  }
}

export default TextModule;