const OvalLandscape = ({ shapeColor, handleClick }) => {
  return (
    <svg
      onClick={handleClick}
      style={{ fill: shapeColor ? shapeColor : null }}
      className="shape shape--ovalLandscape"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 273.09 142.08"
      width="273.09" height="142.08"
    >
      <ellipse cx="136.55" cy="71.04" rx="136.55" ry="71.04" />
    </svg>
  )
};

export default OvalLandscape;