const ContactEnvelope = ({ shapeColor, handleClick }) => {

  return (
    <svg
      onClick={handleClick ? handleClick : null}
      style={{ fill: shapeColor ? shapeColor : null }}
      xmlns="http://www.w3.org/2000/svg"
      width="224"
      height="145"
      viewBox="0 0 224 145"
      className="shape shape--contactEnvelope"
    >
      <g id="Group_9" data-name="Group 9" transform="translate(-96 -136)">
        <g id="Rectangle_6" data-name="Rectangle 6" transform="translate(96 136)" fill="#acffdc" stroke="#000" strokeWidth="3">
          <rect width="224" height="145" rx="6" stroke="none"/>
          <rect x="1.5" y="1.5" width="221" height="142" rx="4.5" fill="none"/>
        </g>
        <path id="Path_5" data-name="Path 5" d="M78.962,425.085l94.915,85.8,92.167-85.8" transform="translate(37.496 -272.487)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
      </g>
    </svg>
  )
};

export default ContactEnvelope;