import LazyloadImage from '../LazyloadImage';

const ImageModule = (props) => {

  const { module } = props;
      
  if (module.value?.path && module.value.path !== '') {
    return (
      <div className="module module--image">
        <LazyloadImage image={module.value} />
      </div>
    );
  } else {
    return null;
  }
}

export default ImageModule;