import { motion } from 'framer-motion';
import parse from 'html-react-parser';

const About = (props) => {

  const { about, contact } = props;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="page page--about"
    >
      {
        about.mainContent &&
        <div className="page--about__content container-fluid">
            <div className="row">
              <div className="col-xxs-12">
                {
                  contact?.email &&
                  <a className="email-address" href={`mailto:${contact.email}`} target="_blank" rel="noreferrer">email me</a>
                }
              </div>
              <div className="col-xxs-12">
                {parse(about.mainContent)}
              </div>
            </div>
            
        </div>
      }
    </motion.div>
  )
}

export default About;